import {createRouter, createWebHistory} from 'vue-router';
import App from './App.vue';
import {fireauth} from '@/firebase';
import {onAuthStateChanged, getIdTokenResult} from 'firebase/auth';
import {useNewStore} from '@/store';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '/',
                component: () => import('./OrgWrapper.vue'),
                children: [
                    // The below routes need to have an active orgDoc
                    {
                        path: '/',
                        name: 'dashboard',
                        exact: true,
                        component: () => import('./components/Dashboard.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Favorites', label: 'Dashboard'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/aggregated-feed',
                        name: 'aggregated-feed',
                        component: () => import('./components/AggregatedFeed.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Favorites', label: 'Aggregated Feed'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/empty',
                        name: 'empty',
                        component: () => import('./components/EmptyPage.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Pages', label: 'Empty Page'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/manage',
                        name: 'manage',
                        component: () => import('./components/SourceManagement/SourceManagement.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Client', label: 'Manage Data Sources'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/manage/:activeTab',
                        name: 'manageTab',
                        component: () => import('./components/SourceManagement/SourceManagement.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Client', label: 'Manage Data Sources'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/notifications',
                        name: 'notifications',
                        component: () => import('./components/Notifications.vue'),
                        meta: {
                            breadcrumb: [{parent: 'User', label: 'Notifications'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/tiktok',
                        name: 'tiktok',
                        component: () => import('./components/TikTok.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'TikTok'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/instagram',
                        name: 'instagram',
                        component: () => import('./components/Instagram.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Instagram'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/x',
                        name: 'x',
                        component: () => import('./components/Twitter.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'X'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/facebook',
                        name: 'facebook',
                        component: () => import('./components/Facebook.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Facebook'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/news',
                        name: 'news',
                        component: () => import('./components/News.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'News'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/email',
                        name: 'email',
                        component: () => import('./components/Emails.vue'),
                        meta: {
                            breadcrumb: [{ parent: 'Data Source', label: 'Competitive Email' }],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/actblue',
                        name: 'actblue',
                        component: () => import('./components/Actblue.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Actblue'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/mailchimp',
                        name: 'mailchimp',
                        component: () => import('./components/Mailchimp.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Mailchimp'}],
                        },
                    },
                    {
                        path: '/ngp',
                        name: 'ngp',
                        component: () => import('./components/NGP.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'NGP'}],
                        },
                    },
                    {
                        path: '/google-analytics',
                        name: 'google-analytics',
                        component: () => import('./components/GoogleAnalytics.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Google Analytics'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/google-postmaster',
                        name: 'google-postmaster',
                        component: () => import('./components/GooglePostmaster.vue'),
                        meta: {
                            breadcrumb: [{ parent: 'Data Source', label: 'Google Postmaster' }],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/partner_meta',
                        name: 'partner-meta',
                        component: () => import('./components/Partner/PartnerMeta.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Partner Data', label: 'Meta Performance'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/partner_youtube',
                        name: 'partner-youtube',
                        component: () => import('./components/Partner/PartnerYoutube.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Partner Data', label: 'Youtube Performance'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/partner_google',
                        name: 'partner-google',
                        component: () => import('./components/Partner/PartnerGoogle.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Partner Data', label: 'Google Search Performance'}],
                        },
                    },
                    {
                        path: '/partner_hulu',
                        name: 'partner-hulu',
                        component: () => import('./components/Partner/PartnerHulu.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Partner Data', label: 'Hulu Performance'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/partner_programmatic',
                        name: 'partner-programmatic',
                        component: () => import('./components/Partner/PartnerProgrammatic.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Partner Data', label: 'Programmatic Ad Performance'}],
                        },
                    },
                    {
                        path: '/partner_domain',
                        name: 'partner-domain',
                        component: () => import('./components/Partner/PartnerDomain.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Partner Data', label: 'Domain Performance'}],
                        },
                    },
                    {
                        path: '/partner_geo',
                        name: 'partner-geo',
                        component: () => import('./components/Partner/PartnerGeo.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Partner Data', label: 'Geo Performance'}],
                        },
                    },
                    {
                        path: '/data_appending',
                        name: 'data-appending',
                        component: () => import('./components/AccurateAppend.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Partner Data', label: 'Data Appending'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/ad_impact_linear_tv',
                        name: 'ad-impact-linear-tv',
                        component: () => import('./components/Competitive/LinearTV.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Competitive Data', label: 'Linear TV'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/ad_impact_radio',
                        name: 'ad-impact-radio',
                        component: () => import('./components/Competitive/Radio.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Competitive Data', label: 'Radio'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/ad_impact_ott_ctv',
                        name: 'ad-impact-ott-ctv',
                        component: () => import('./components/Competitive/OTT_CTV.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Competitive Data', label: 'OTT/CTV'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/scan_meta',
                        name: 'scan-meta',
                        component: () => import('./components/SCAN/SCAN.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Meta Political Ads'}],
                            requiresAuth: true,
                            source: 'meta',
                        },
                    },
                    {
                        path: '/scan_google',
                        name: 'scan-google',
                        component: () => import('./components/SCAN/SCAN.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Google Political Ads'}],
                            requiresAuth: true,
                            source: 'google',
                        },
                    },
                    {
                        path: '/scan_snap',
                        name: 'scan-snap',
                        component: () => import('./components/SCAN/SCAN.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Snap Political Ads'}],
                            requiresAuth: true,
                            source: 'snap',
                        },
                    },
                    {
                        path: '/scan_x',
                        name: 'scan-x',
                        component: () => import('./components/SCAN/SCAN.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'X Political Ads'}],
                            requiresAuth: true,
                            source: 'x',
                        },
                    },
                    {
                        path: '/scan_roku',
                        name: 'scan-roku',
                        component: () => import('./components/SCAN/SCAN.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Roku Political Ads'}],
                            requiresAuth: true,
                            source: 'roku',
                        },
                    },
                    {
                        path: '/tally-ballot-returns',
                        name: 'tally-ballot-returns',
                        component: () => import('./components/TallyBallotReturns.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'Tally Ballot Returns'}],
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/scan_api',
                        name: 'scan-api',
                        component: () => import('./components/SCAN/endpoint/SCANapi.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'SCAN API Docs'}],
                            requiresAuth: true,
                            source: 'x',
                        },
                    },
                    {
                        path: '/tv_eyes',
                        name: 'tv-eyes',
                        component: () => import('./components/TVeyes.vue'),
                        meta: {
                            breadcrumb: [{parent: 'Data Source', label: 'TV Eyes'}],
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: '/settings',
                name: 'settings',
                component: () => import('./components/Settings.vue'),
                meta: {
                    breadcrumb: [{parent: 'User', label: 'Settings'}],
                    requiresAuth: true,
                },
            },
            {
                path: '/admin',
                name: 'admin',
                component: () => import('./components/SettingsAdmin.vue'),
                meta: {
                    breadcrumb: [{parent: 'Staff', label: 'Admin Settings'}],
                    requiresAuth: true,
                    requiresStaff: true,
                },
            },
            {
                path: '/definitions',
                name: 'definitions',
                component: () => import('./components/Definitions.vue'),
                meta: {
                    breadcrumb: [{parent: 'Pages', label: 'Term Definitions'}],
                    requiresAuth: true,
                },
            },
            {
                path: '/instructions',
                name: 'instructions',
                component: () => import('./components/Instructions.vue'),
                meta: {
                    breadcrumb: [{parent: 'Pages', label: 'Site Instructions'}],
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {left: 0, top: 0};
    },
});

function currentUser() {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
            fireauth,
            (user) => {
                const store = useNewStore();
                store.user = user;
                unsubscribe();
                resolve(user);
            },
            reject
        );
    });
}

function currentStaff() {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
            fireauth,
            async (user) => {
                let staff = await getIdTokenResult(user);
                if (user) unsubscribe();
                resolve(staff.claims.staff !== undefined ? true : false);
            },
            reject
        );
    });
}

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresStaff = to.matched.some((record) => record.meta.requiresStaff);
    const store = useNewStore();

    if (await currentUser()) {
        if (!store.userDoc) {
            await store.init();
        }
    }
    //     else if (noAuthRequired) {
    //         next();
    //     }
    //     else {
    //         next({
    //             name: 'login',
    //             query: { redirect: to.fullPath },
    //         });
    //     }
    // });
    if (requiresStaff && !(await currentStaff())) {
        next('login');
    } else if (requiresAuth && !(await currentUser())) {
        next('login');
    } else {
        next();
    }
});

export default router;
